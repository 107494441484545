
import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import {ListGroup, Card, Badge,  Spinner  } from 'react-bootstrap';
import './SpecialTool.scss';
import { toast } from 'react-toastify';
import {handleGetManualHtml} from '../../api/backend'
export interface Manual {
    manualName: string;
    manualId: string;
    matchedTools: string[];
    additionalTools: string[];
    missingTools: string[]
    itemMpText: string
  }

export type ManualItem = {
    manualName: string;
    matchedSpecialTools: string;
    additionalSpecialTools: string;
    missingSpecialTools: string;
    itemMpText: string;
    manualID:string
  };

interface MatchingManualsProps {
    manuals: Manual[];
  }


const MatchingManuals:React.FC<MatchingManualsProps> = ({manuals}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);  


    const copyFilenameToClipboard = (manualId: string) => {
        toast(t("SpecialTool.CopyIdClipboardMessage").replace("{}", manualId), { type: "success"})
        navigator.clipboard.writeText(manualId)
    }

    const handleManualClick = async (manual:Manual) => {
        try {
            setLoading(true)
            let htmlResponse = await handleGetManualHtml(manual.manualId)
            if (htmlResponse) {
                // Open a new tab
                const newWindow = window.open('', '_blank')
                if (newWindow) {
                    newWindow.document.open();
                    newWindow.document.write(htmlResponse);
                    newWindow.document.close();
                  }        
            }
            else{
              toast.warn(t("Manual.NotFound"), {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
            }
        } catch (error) {   
          toast.error(t("Manual.OpeningError"), {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
            });
        }
        finally {
            setLoading(false);  // Hide spinner
        }
};

   return (
    <div className="d-flex justify-content-center manual-details-listgroup mt-4">
        {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}


    <ListGroup className="w-75">
        {manuals.map((manual, index) => (
            <ListGroup.Item key={index} className="manual-item">
            <div className="row ">
            <div className="col-2">
              </div>
              <div className="col-8 text-center mb-0">
                <h5>{manual.itemMpText}</h5>
              </div>
              <div className="col-2 text-end mb-0">
                <p className='manual-id' onDoubleClick={() => copyFilenameToClipboard(manual.manualId)}>{`ID: ${manual.manualId}`}</p>
              </div>
            </div>
            <div className="row align-items-center">
            <div className="col-3  text-center">
              </div>
              <div className="col-6  text-center">
              <a href="#" onClick={() => handleManualClick(manual)}>
                <p className="manual-name">{manual.manualName}</p>
                </a>
              </div> 
              <div className="col-3  text-center">
              </div>         
            </div>
                <Card.Body>
                    <Card.Title className="fs-6">{t("SpecialTool.MatchedTools")}</Card.Title>
                    <div className="tool-labels">
                        {manual.matchedTools.map((tool, idx) => (
                            <Badge key={idx} className="matched-tool-label">
                                {tool}
                            </Badge>
                        ))}
                    </div>
                    <hr />
                    <Card.Title className="fs-6">{t("SpecialTool.AdditionalTools")}</Card.Title>
                    <div className="tool-labels">
                        {manual.additionalTools.map((tool, idx) => (
                            <Badge key={idx} className="additional-tool-label">
                                {tool}
                            </Badge>
                        ))}
                    </div>
                    <hr />
                    <Card.Title className="fs-6">{t("SpecialTool.MissingTools")}</Card.Title>
                    <div className="tool-labels">
                        {manual.missingTools.map((tool, idx) => (
                            <Badge key={idx} className="notfound-tool-label">
                                {tool}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </ListGroup.Item>
        ))}
    </ListGroup>
</div>
);
};

export default MatchingManuals