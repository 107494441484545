import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  show: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, title, message, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {t('ConfirmationModal.Cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
        {t('ConfirmationModal.Confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default ConfirmationModal;
