import React, { useEffect, useState } from 'react';
// import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './App.scss';
import { AppNavbar } from './components';

import { Amplify, } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { AuthState, useAuthentication, useI18nState, usePdfUploadState, useResultsState } from './store';
import { getLocalItem, setLocalItem } from './store/slices/pdfuploadSlice'
import awsconfig from './modified_aws-export';
import Footer from './components/Footer/Footer';
import { useTranslation } from "react-i18next";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import ConfirmationModal from "./components/ConfirmationModal/ConfirmationModal"
Amplify.configure(awsconfig);

function App() {
  const { authState, authInitialized, initializeAuthentication } = useAuthentication();
  const { getResultCount, emptyResults } = useResultsState()
  const { uploadComplete, clearUploadedFiles, clearFileMap, setUploadComplete } = usePdfUploadState()
  const { setLanguage } = useI18nState();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [navigateTo, setNavigateTo] = useState<string | null>(null);

  useEffect(() => {
    if (!authInitialized) {
      initializeAuthentication();
    }
  }, [authInitialized, initializeAuthentication])

  React.useEffect(() => {
    // setLanguage(selectedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLanguage]);

  useEffect(() => {
    document.title = t("Title_landing_page");
  }, [t]);

  const [activeKey, setActiveKey] = useState<string>("/image-matcher");

  useEffect(() => {
    // Update activeKey based on current pathname
    const path = location.pathname;
    if (path.startsWith("/image-matcher")) {
      setActiveKey("/image-matcher");
    } else {
      setActiveKey(path);
    }
  }, [location]);



  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, selectedKey: string) => {
    event.preventDefault();

    if (selectedKey !== activeKey) {
      const resultCount = getResultCount()
      if (activeKey === "/image-matcher" && resultCount > 0) { 
        setLocalItem('openInNewTab', false)
        setShowModal(true)
        setNavigateTo(selectedKey)
      }
      else if (activeKey === "/special-tools-finder" && uploadComplete) {
        setNavigateTo(selectedKey)
        setShowModal(true)
      }
      else if((activeKey === "/image-matcher" && resultCount == 0)|| (activeKey === "/special-tools-finder" && !uploadComplete)) 
        {
          navigate(selectedKey)
           setActiveKey(selectedKey)
        }
    }
  };

  const handleConfirm = () => {
    if (navigateTo) {
      if(navigateTo==="/image-matcher" && activeKey==="/special-tools-finder") {
        clearUploadedFiles()
        clearFileMap()
        setUploadComplete(false)
      }
      
      navigate(navigateTo);
      setActiveKey(navigateTo);
      }
      
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  }


  const openInNewTab = getLocalItem('openInNewTab') === 'true';
  return (
    <>
      <div className="App container">
        <AppNavbar></AppNavbar>
        {authState === AuthState.SignedIn && !openInNewTab ?
          <Nav justify variant="underline" activeKey={activeKey} >
            <Nav.Item>
              <Nav.Link eventKey="/image-matcher" style={{ color: 'black' }} onClick={(e) => handleClick(e, "/image-matcher")}
              >TecRMI Image Matcher</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/special-tools-finder" style={{ color: 'black' }} onClick={(e) => handleClick(e, "/special-tools-finder")} >Special Tools Finder</Nav.Link>
            </Nav.Item>
            <ConfirmationModal
              show={showModal}
              title={t("ConfirmationModal.ConfirmationTitle")}
              message={t("ConfirmationModal.ConfirmationMessage")}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          </Nav> : null
        }

        <main className="login-container">
          {authState === AuthState.SignedIn ?
            <>
              <Outlet />
            </>
            // <Routes>
            //   <Route path="/" element={<DropZone />}></Route>
            //   <Route path="/config" element={<Config />}></Route>
            //   <Route path="/result" Component={ResultPage} />
            // </Routes>
            : null
          }
        </main>
        <Footer />
      </div>
      <ToastContainer position="top-center" />
    </>
  );
}


export default App;
