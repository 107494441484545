import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import SelectTopK from './SelectTopK';
import ShowMetadataSelector from "./ShowMetaDataSelector";
import ShowDistance from "./ShowDistance";
// import SelectTopProductCategories from "./SelectTopProductCategories";
import ShowProductCategories from "./ShowProductCategories";


const Config = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();

    return (
        <div className="card card-body m-2 p-2" style={{ width: "12em" }}>
            <SelectTopK></SelectTopK>
            <ShowProductCategories></ShowProductCategories>
            {/* <SelectTopProductCategories></SelectTopProductCategories> */}
            <ShowMetadataSelector></ShowMetadataSelector>
            <ShowDistance></ShowDistance>
            <button onClick={() => navigate(-1)} className='btn btn-secondary'>{t('Button.back')}</button>
        </div>
    )
}

export default Config