import { useStore } from "./useStore";

export const usePdfUploadState = () => {

    const {
        uploadComplete,
        uploadedFiles,
        fileMap,
        setUploadComplete,
        setFileMap,
        setUploadedFiles,
        clearFileMap,
        clearUploadedFiles
    } = useStore();

    return {
        uploadComplete,
        uploadedFiles,
        fileMap,
        setUploadComplete,
        setFileMap,
        setUploadedFiles,
        clearFileMap,
        clearUploadedFiles
    }
}