interface resultsState {
    results: string
    resultcount: number
}
export const ResultsState: resultsState = {
    results: "{}",
    resultcount: 0,
}

const getLocalItem = (item: string, default_value: any = '') => {
    let item_value = window.localStorage.getItem(item)
    if (item_value) {
        return item_value
    } else {
        return default_value
    }
}
const setLocalItem = (item: string, value: any = '') => {
    window.localStorage.setItem(item, value);
}

export const createResultsSlice = (set: any, get: any) => ({
    ...createResultsStates(set, get),
    ...createResultsActions(set, get),
});

const createResultsStates = (set: any, get: any) => ({
    results: getLocalItem('results', ResultsState.results) as string,
    setResults: (id: string, item: string) =>
        set((state: any) => {
            let parsed = JSON.parse(state.results)
            parsed[id] = JSON.parse(item)
            setLocalItem('results', JSON.stringify(parsed))
            state.results = JSON.stringify(parsed)
        }),
    emptyResults: () =>
        set((state: any) => {
            let emptyvalue = "{}"
            window.localStorage.removeItem('results')
            state.results = emptyvalue
            state.resultcount = 0
        }),
    setResultCount: (count: number) => 
        set((state: any) => {
            state.resultcount = count
        }),
    getResultLength: () => {
        const { results }  = get();
        return Object.keys(JSON.parse(results)).length
    },
    getResultCount: () => {
        const { resultcount }  = get();
        return resultcount
    }
});

const createResultsActions = (set: any, get: any) => ({
});