/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCalculationFinished = /* GraphQL */ `subscription OnCalculationFinished($id: String!) {
  onCalculationFinished(id: $id) {
    id
    results
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCalculationFinishedSubscriptionVariables,
  APITypes.OnCalculationFinishedSubscription
>;
export const onspecialToolsExtractionFinished = /* GraphQL */ `subscription OnspecialToolsExtractionFinished($id: String!) {
  onspecialToolsExtractionFinished(id: $id) {
    id
    results
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnspecialToolsExtractionFinishedSubscriptionVariables,
  APITypes.OnspecialToolsExtractionFinishedSubscription
>;
