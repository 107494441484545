import { useStore } from "./useStore";

export const useResultsState = () => {

    const {
        results,
        setResults,
        emptyResults,
        setResultCount,
        getResultLength,
        getResultCount,
    } = useStore();

    return {
        results,
        setResults,
        emptyResults,
        setResultCount,
        getResultLength,
        getResultCount,
    }
}