import { useTranslation } from "react-i18next";
import { useConfigState } from '../../store';
import Form from 'react-bootstrap/Form';
import { useRef } from 'react';

const ShowDistance = () => {
    const showDistance_ref = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const { setshowDistance, showDistance } = useConfigState()

    const change = (elem: any) => {
        if (showDistance_ref.current?.value) {
            setshowDistance(Boolean(showDistance_ref.current?.checked))
        }
    }

    return (
        <>
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch-showDistance"
                    label={t('Config.ShowDistance')}
                    onChange={(elem) => change(elem)}
                    ref={showDistance_ref}
                    checked={showDistance}
                />
            </Form>
        </>
    )
}

export default ShowDistance