import React, { useState } from 'react';
import Sidebar from './Sidebar';
import MatchingManual, { Manual, ManualItem } from './MatchingManual'; // Importing Manual and MatchingManual components
import { handleRefineMatches } from '../../api/backend'; // Assuming handleRefineMatches returns some response
import { useTranslation } from "react-i18next";

interface SpecialToolProps {
  tools: string[];
  onBack: () => void;
  manuals: Manual[];
}



const SpecialTool: React.FC<SpecialToolProps> = ({ tools, onBack, manuals }) => { 
  const [filteredManuals, setFilteredManuals] = useState<Manual[]>(manuals);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const onFilterMatches = async (filteredTools: string[],topK:number) => {
    setIsLoading(true);
    const stringifiedTools = JSON.stringify(filteredTools);
    const manualResponse = await handleRefineMatches(stringifiedTools,topK)
    if(manualResponse.length > 0 ) 
      {
        const matchingManuals: Manual[] = [];
        manualResponse.forEach((item:ManualItem) =>  {
            if (item) {
                // const item = manualResponse[id];
                const manual: Manual = {
                    manualName:item.manualName,
                    manualId: item.manualID,
                    matchedTools: item.matchedSpecialTools.split(', ').map((tool:string) => tool.trim()),
                    additionalTools: item.additionalSpecialTools.split(', ').map((tool:string) => tool.trim()),
                    missingTools: item.missingSpecialTools.split(', ').map((tool:string) => tool.trim()),
                    itemMpText:item.itemMpText,
                };
                matchingManuals.push(manual);
            }
        })
        setFilteredManuals(matchingManuals)
      }
      setIsLoading(false);
  }


  const handleDownload = () => {
    const csvContent = convertToCSV(filteredManuals);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'matchingManuals.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const convertToCSV = (data: Manual[]) => {
    const headers = ['ManualId','ManualName','ItemMpText', 'MatchedTools', 'AdditionalTools','MissingTools'];
    const rows = data.map(manual => [
        manual.manualId,
        manual.manualName,
        manual.itemMpText,
        manual.matchedTools.join(', '),
        manual.additionalTools.join(', '),
        manual.missingTools.join(', ')
    ]);
    
    const csvRows = [
        headers.join(';'), // header row first
        ...rows.map(row => row.join(';')) // data rows
    ];
    
    return csvRows.join('\n');
};

  return (
    <div>
      <Sidebar tools={tools} onBack={onBack} onFilterMatches={onFilterMatches} handleDownload={handleDownload} isLoading={isLoading}/>
      <MatchingManual manuals={filteredManuals} />
    </div>
  );
};

export default SpecialTool;
