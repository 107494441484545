import { useLoaderData,  useNavigate } from "react-router-dom";
import ItemResults from "../ItemResults";
import { DropFile } from "../DropZone/DropZone";
import { useTranslation } from "react-i18next";
import { getDropZoneResult } from "../../api/backend";

interface ManufacturerResultPageProps {
}

export const loader = async ({ params }: any) => {
    // return params
    let dropzoneResult: DropFile = await getDropZoneResult(params.uuid) as DropFile
    if (dropzoneResult && dropzoneResult.result[params.manufacturer]) {
        dropzoneResult.result['selected'] = dropzoneResult.result[params.manufacturer]
    } else {
        throw new Error("Couldn't find selected manufacturer!");
    }
    return dropzoneResult
}

const ManufacturerResultPage: React.FC<ManufacturerResultPageProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let current_file: DropFile = useLoaderData() as DropFile
    let elements = current_file.result['selected']

    // return (
    //     <ResultPage />
    // )
    return (
        <div className='card border-0 d-md-block' style={{ width: "90%" }}>
            <button className="btn btn-secondary" type="button" onClick={() => navigate('/image-matcher/results/'+current_file.id)}>
                {t("Button.back")}
            </button>
            <img src={current_file.preview} alt='' className='rounded mx-auto d-block' />
            <div className="row" style={{ marginTop: "20px" }}>
                {
                    elements.map((elem: any) => {
                        return (
                            <div className="col justify-content-center d-flex" key={elem.id}>
                                <ItemResults score={elem.score as number} metadata={elem.metadata} elements={elem.elements} dropfile={current_file}></ItemResults>
                            </div>
                        )
                    })
                }
            </div>
            <button className="btn btn-secondary" type="button" onClick={() => navigate('/image-matcher/results/'+current_file.id)}>
                {t("Button.back")}
            </button>
        </div>
    );
}

export default ManufacturerResultPage