export const createPdfFileUploadSlice = (set: any, get: any) => ({
    ...createPdfFileUploadStates(set, get),
    ...createPdfFileUploadActions(set, get),
});

export const getLocalItem = (item: string, default_value: any = '') => {
    let item_value = window.localStorage.getItem(item)
    if (item_value) {
        return item_value
    } else {
        return default_value
    }
}
export const setLocalItem = (item: string, value: any = '') => {
    window.localStorage.setItem(item, value);
}

const createPdfFileUploadStates = (set: any, get: any) => ({
    uploadComplete: false,
    fileMap: {},
    uploadedFiles: [],
    filesUploaded: false,
    setUploadComplete: (status: boolean) => set({ uploadComplete: status }),
    setFileMap: (fileMap: any) =>
        set((state: any) => ({
            fileMap: 
                {
                    [fileMap.fileName]: fileMap.eTag,
                    ...state.fileMap
                },
                
            
        })),

    setUploadedFiles: (file: File) =>
        set((state: any) => ({
            uploadedFiles: [
                file,
                ...state.uploadedFiles
            ]
        })),
    clearFileMap: () => {
            set({ fileMap: {} });
        },
    
    clearUploadedFiles: () => {
            set({ uploadedFiles: [] });
        }
});

const createPdfFileUploadActions = (set: any, get: any) => ({
});