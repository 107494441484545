import { useStore } from "./useStore";

export const useFilterState = () => {

    const {
        itemGroupFilter,
        setItemGroupFilter,
        manufacturerFilter, 
        setManufacturerFilter,
        namespace,
        setNamespace,
        emptyFilter    
    } = useStore();

    return {
        itemGroupFilter,
        setItemGroupFilter,
        manufacturerFilter, 
        setManufacturerFilter,
        namespace,
        setNamespace,
        emptyFilter  
    }
}