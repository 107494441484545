import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createAuthSlice, createI18nSlice, createConfigSlice, createResultsSlice, createFilterSlice, createPdfFileUploadSlice} from "../slices";

const createRootSlice = (set: any, get: any) => ({
  ...createAuthSlice(set, get),
  ...createI18nSlice(set, get),
  ...createConfigSlice(set, get),
  ...createResultsSlice(set,get),
  ...createFilterSlice(set,get),
  ...createPdfFileUploadSlice(set,get)
});

export const useStore = create(immer(createRootSlice));
