interface filterState {
    itemGroupFilter: string
    manufacturerFilter: string,
    namespace: string
}
export const FilterState: filterState = {
    itemGroupFilter: "[]",
    manufacturerFilter: "[]",
    namespace: ""
}

const getLocalItem = (item: string, default_value: any = '') => {
    let item_value = window.localStorage.getItem(item)
    if (item_value) {
        return item_value
    } else {
        return default_value
    }
}
const setLocalItem = (item: string, value: any = '') => {
    window.localStorage.setItem(item, value);
}

export const createFilterSlice = (set: any, get: any) => ({
    ...createFilterStates(set),
    ...createFilterActions(set, get),
});

const createFilterStates = (set: any) => ({
    itemGroupFilter: getLocalItem('itemGroupFilter', FilterState.itemGroupFilter) as string,
    setItemGroupFilter: (itemGroupFilter: string) =>
        set((state: any) => {
            setLocalItem('itemGroupFilter', itemGroupFilter)
            state.itemGroupFilter = itemGroupFilter;
        }),
    manufacturerFilter: getLocalItem('manufacturerFilter', FilterState.manufacturerFilter) as string,
    setManufacturerFilter: (manufacturerFilter: string) =>
        set((state: any) => {
            setLocalItem('manufacturerFilter', manufacturerFilter)
            state.manufacturerFilter = manufacturerFilter;
        }),
    namespace: getLocalItem('namespace', FilterState.namespace) as string,
    setNamespace: (namespace: string) =>
        set((state: any) => {
            setLocalItem('namespace', namespace)
            state.namespace = namespace;
        }),
    emptyFilter: () =>
        set((state: any) => {
            let emptyvalue = "[]"
            window.localStorage.removeItem('itemGroupFilter')
            window.localStorage.removeItem('manufacturerFilter')
            window.localStorage.removeItem('namespace')
            state.itemGroupFilter = emptyvalue
            state.manufacturerFilter = emptyvalue
            state.namespace = ""
        })
});

const createFilterActions = (set: any, get: any) => ({
});