import React, { useState } from 'react';
import { Button, Offcanvas, Form, Spinner  } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import './SpecialTool.scss';

interface SidebarProps {
    tools: string[];
    onBack: () => void;
    onFilterMatches: (selectedTools: string[], topK:number) => void;
    handleDownload: () => void;
    isLoading: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ tools, onBack, onFilterMatches, handleDownload, isLoading }) => {
    const [checkedTools, setCheckedTools] = useState(tools.map(() => true));
    const [topK, settopK] = useState<number>(5);
    const { t } = useTranslation();
    const handleCheckboxChange = (index: number) => {
        const newCheckedTools = [...checkedTools];
        newCheckedTools[index] = !newCheckedTools[index];
        setCheckedTools(newCheckedTools);
    };

    const handleAction = (event: any) => {
        event.preventDefault();
        const selectedTools = tools.filter((tool, index) => checkedTools[index]);
        onFilterMatches(selectedTools,topK);
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        settopK(parseInt(value));
    };

    const onClickDownload = () => {
        handleDownload()
    };

    return (
        <Offcanvas show={true} scroll={true} backdrop={false} className="custom-offcanvas">
            <Offcanvas.Header className="d-flex justify-content-center align-items-center">
                <Offcanvas.Title></Offcanvas.Title>
                <div className="text-center mt-3">
                <Button variant="secondary" onClick={onBack } disabled={isLoading}>{t("SpecialTool.BackButton")}</Button>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div>{t("SpecialTool.SpecialTools")}</div>
                <Form onSubmit={handleAction}>
                    {tools.map((tool, index) => (
                        <Form.Check
                            aria-label="option 1"
                            key={index}
                            type="checkbox"
                            id={`tool-${index}`}
                            label={tool}
                            checked={checkedTools[index]}
                            onChange={() => handleCheckboxChange(index)}
                            disabled={isLoading}
                        />
                    ))}
                    <Form.Group className="mt-3">
                        <Form.Label>{t("PdfFileUpload.TopKResults")}</Form.Label>
                        <Form.Control
                            type="number"
                            value={topK}
                            onChange={handleNumberChange}
                            disabled={isLoading}
                        />
                    </Form.Group>
                <div className="text-center mt-3">
                <Button variant="secondary"  disabled={isLoading}>
                        {isLoading ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}{t("DropZone.loading")}
                            </>
                        ) : (
                            t("SpecialTool.FilterButton")
                        )}
                    </Button>
                </div>
                </Form>

                <div className="text-center mt-3">
                    <Button variant="secondary" onClick={onClickDownload} disabled={isLoading}>{t("SpecialTool.DownloadButton")}</Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default Sidebar;
