import { useRouteError, isRouteErrorResponse} from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  const { t } = useTranslation();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.error?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      {/* <p>{t("ErrorPage")} <a href={`mailto:ccs-datascience@tecalliance.net?body=ErrorMessage: ${errorMessage}`}>ccs-datascience@tecalliance.net</a></p> */}
      <p>{t("ErrorPage")}</p>
      <p>
        <i>{errorMessage}</i>
      </p>
    </div>
  );
}



export default ErrorPage